/**
 * A simple web component base class with helpers and basic reactivity and UI state binding
 * Written by Chris Elliott
 * Version 09.09.2024.001
 */

export class BaseWC extends HTMLElement {
    data = {}
  
    constructor(dataProps = {}, noRender = false) {
        super().attachShadow({ mode: 'open' })
        this.setData(dataProps)
        if(!noRender) this.shadowRoot.innerHTML = this.render()
        this.wireEventBinds()
        this.setBindings(this.data)
        this.wireInputs()
    }
  
    // Reserved methods for data handling (setData & watch)
    watch(propName, callback) {
        this.addEventListener(propName, e => { callback(e.detail, e) })
    }

    watchAll(data = {}) {}

    setData(props = {}) {
        this.data = this.reactive(props)
    }

    resetData(props = {}) {
        Object.keys(props).forEach(key => this.data[key] = props[key])
    }


    // Helpers
    select(selector) {
        return this.shadowRoot ? this.shadowRoot.querySelector(selector) : this.querySelector(selector)
    }

    selectAll(selector) {
        return this.shadowRoot ? this.shadowRoot.querySelectorAll(selector) : this.querySelectorAll(selector)
    }

    isObject(obj) {
        return Object.prototype.toString.call(obj) === '[object Object]'
    }

    isArray(arr) {
        return Array.isArray(arr)
    }


    // Simple Event Binding (e.g. data-event="click.once.prevent:funcName('single prop')")
    wireEventBinds() {
        const elems = this.selectAll('[data-event]')

        elems.forEach(elem => {
            const parseData = elem.dataset.event.split(':')
            const eventDetail = {
                name: parseData[0].replace(/\..*/, ''),
                prop: (parseData[1].match(/\(/)) ? parseData[1].match(/\((.*?)\)/)[1].replace(/\'/g, `"`) : null,
                func: parseData[1].replace(/\(.*/, ''),
                modifiers: (parseData[0].match(/\./)) ? parseData[0].split('.') : ''
            }

            elem.addEventListener(eventDetail.name, (event) => {
                const fn = this[eventDetail.func].bind(this)

                // Prevent Default
                if(eventDetail.modifiers.includes('prevent')) event.preventDefault()

                if(typeof fn === 'function') fn(event, elem, JSON.parse(eventDetail.prop))
            }, { 
                // Options
                once: eventDetail.modifiers.includes('once') ,
                capture: eventDetail.modifiers.includes('capture')
            })
        })
    }

    // Object structure recreation
    createPropObject(value, path) {
        return path.reduceRight((value, key) => ({[key]: value}), value)
    }

    // Simple UI Event Binding & Reactive Props
    // On HTML in render() add data-bind="propName"
    reactive(obj = {}) {
        const emit = (type, detail, target, value, path) => {
            this.setBindings(this.createPropObject(value, path))

            let event = new CustomEvent(type, {
                bubbles: true,
                cancelable: true,
                detail: detail
            })
    
            return this.dispatchEvent(event)
        }

        const handler = (obj, path = []) => {
            return {
                get: (target, property) => {
                    if (property === '_isProxy') return true
                    
                    const value = Reflect.get(target, property)
                
                    if (typeof value == 'undefined') return
                  
                    if (!value._isProxy && typeof value === 'object')
                        target[property] = new Proxy(value, handler(obj, [...path, property]))
                      
                    return target[property]
                },
    
                set: (target, property, value) => {
                    if(target[property] === value) return true
                    Reflect.set(target, property, value)
                    
                    emit(property, obj, target, value, [...path, property])
                    this.watchAll(obj)
                    return true
                },
    
                deleteProperty: (target, property) => {
                    delete target[property]
                    emit(property, obj, target)
                    this.watchAll(obj)
                    return true
                }
            }
        }

        return new Proxy(obj, handler(obj))
    }

    setBindings(obj) {
        const _getBindKey = (key, obj) => {
            return Object.keys(obj).map(k => this.isObject(obj[k]) ? `${key}.${_getBindKey(k, obj[k])}` : `${key}.${k}`);
        }

        Object.entries(obj).forEach(([key, value]) => {
            const bindKey = this.isObject(value) ? _getBindKey(key, value) : key
            const bindKeys = this.isArray(bindKey) ? bindKey : [bindKey]
            
            bindKeys.forEach(key => {
                this.updateBindings(key, value)
                this.toggleAttrs(key, value)
                this.toggleCSSClasses(key, value)
            })
        })
    }

    updateBindings(property, value) {
        const bindings = this.selectAll(`[data-bind$="${property}"]`)
        
        bindings.forEach(elem => {
            const dataProp = elem.dataset.bind
            const bindValue = dataProp.includes('.') ? dataProp.split('.').slice(1).reduce((obj, p) => obj[p], value) : value
            // 'a.b.etc'.split('.').reduce((o,i)=> o[i], obj)
            
            try {
                if(elem.nodeName === 'SELECT') {
                    elem.querySelectorAll('option').forEach(opt => opt.removeAttribute('selected'))
                    elem.querySelector(`[value="${bindValue.toString()}"]`).setAttribute('selected', true)
                    return
                }
            }
            catch(err) {
                console.log('Value is not compatible with SELECT (likely HTML)')
            }
            
            (/<\/?[a-z][\s\S]*>/g.test(bindValue)) ? elem.innerHTML = bindValue : elem.textContent = bindValue
            if(elem.nodeName === 'INPUT' ||  
            elem.nodeName === 'TEXTAREA') elem.value = bindValue.toString()
            elem.checked = bindValue
        })
    }
    

    // Uses bindings props to dynamically change attributes (usage: data-attributes="attr-name:prop, attr-name:prop")
    toggleAttrs(property, value) {
        const bindings = this.selectAll(`[data-attributes]`)

        bindings.forEach(elem => {
            const data = elem.dataset.attributes.split(', ')
   
            data.forEach(attr => {
                const arr = attr.split(':')
                
                if( arr[1] !== property) return
            
                if(value) elem.setAttribute(arr[0], value)
                if(!value) elem.removeAttribute(arr[0])
            })
           
        })
    }

    // CSS class toggling (usage: data-toggle="className:dataProp, className:dataProp")
    toggleCSSClasses(property, value) {
        const bindings = this.selectAll(`[data-class]`)

        bindings.forEach(elem => {
            const data = elem.dataset.class.split(', ')
            
            data.forEach(item => {
                const arr = item.split(':')
                
                if(arr[1] !== property) return
            
                if(value && !elem.classList.contains(arr[0])) elem.classList.add(arr[0])
                if(!value && elem.classList.contains(arr[0])) elem.classList.remove(arr[0])
            })
           
        })
    }

    wireInputs() {
        const inputs = this.selectAll(`[data-bind]`)
        const _handleProps = (propStr, value) => {
            if(!propStr.includes('.')) {
                this.data[propStr] = value
                return
            }

            const props = propStr.split('.')
            let data = this.data

            props.forEach((prop, idx) => {
                if(idx === props.length - 1) return
                if(this.isObject(data)) data = data[prop]
            })

            data[props.at(-1)] = value
        }

        inputs.forEach(elem => {
            if(elem.nodeName !== 'INPUT' && elem.nodeName !== 'TEXTAREA' && elem.nodeName !== 'SELECT') return
        
            elem.addEventListener('change', () => {
                if(elem.type !== 'checkbox') _handleProps(elem.dataset.bind, elem.value)
                if(elem.type === 'checkbox') _handleProps(elem.dataset.bind, elem.checked) 
            })
        })
    }


    // Render default
    render() {
        console.error(`Your component ${this.outerHTML} must have a render method`)
        return ''
    }
}