import { BaseWC } from "./BaseWC";

class ZeroMenu extends BaseWC {
    static observedAttributes = ['open']

    constructor() {
        super()
        this.menu = this.shadowRoot.querySelector('[data-menu]')
        this.menu.addEventListener('close', () => this.removeAttribute('open'))
        this.shadowRoot.querySelectorAll('.nav-link').forEach(item => item.setAttribute('part', 'focus'))
    }

    attributeChangedCallback(name, oldVal, newVal) {
        if(name === 'open' && this.hasAttribute('open')) {
            this.menu.showModal()
            this.setAttribute('aria-expanded', 'true')
            return
        }

        this.menu.close()
        this.setAttribute('aria-expanded', 'false')
    }

    connectedCallback() {
        this.addEventListener('click', event => {
            this.removeAttribute('open')
        })
    }

    content() {
        const markup = this.querySelector('div').cloneNode(true)
        this.querySelector('div').remove()
        return markup.outerHTML
    }

    render() {
        return String.raw`
            <style>
                :host {
                    --font-size: 1.8rem;
                    --width: 46rem;
                    display: grid;
                    inset: 0;
                    justify-content: end;
                    opacity: 0;
                    position: fixed;
                    -webkit-user-select: none;
                    user-select: none;
                    visibility: hidden;
                    z-index: 1000000;
                }

                :host([open]) {
                    opacity: 1;
                    visibility: visible;
                }

                @media (max-width: 960px) {
                    :host {
                        --width: 50vw;
                    }
                }

                @media (max-width: 700px) {
                    :host {
                        --width: 95vw;
                        --font-size: 1.6rem;
                    }
                }

                * {
                   box-sizing: border-box;
                   font-family: var(--font);
                }

                ul, li {
                    all: unset;
                    box-sizing: border-box;
                }

                .menu {
                    background-color: var(--color-1-dkr);
                    border: none;
                    border-radius: 2rem;
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;
                    height: 100%;
                    inset: 0 2rem 0 auto;
                    transform: translateX(100vw);
                    transition: transform 300ms ease;
                    transition-delay: 100ms;
                    width: var(--width);
                }

                .menu[open] {
                    transform: translateX(0);
                }

                @keyframes backdropIn {
                    from {
                        opacity: 0;
                    }

                    to {
                        opacity: 0.9;
                    }
                }

                ::backdrop {
                    animation: backdropIn 1s ease;
                    animation-fill-mode: forwards;
                    background-color: hsl(213, 100%, 25%);
                    opacity: 0.8;
                }

                .header {
                    align-items: center;
                    color: var(--color-1-lt);
                    display: flex;
                    gap: 0.5em;
                    padding-inline: var(--gap-sm);
                    padding-top: 2rem;
                }

                .close {
                    all: unset;
                    aspect-ratio: 1;
                    background-color: var(--content);
                    border-radius: 0.5rem;
                    box-sizing: border-box;
                    color: var(--color-1-dkr);
                    cursor: pointer;
                    font-size: var(--txt-sm);
                    inset: 2rem 2rem auto auto;
                    margin-left: auto;
                    position: absolute;
                    transition: background 300ms ease;
                    width: 3rem;
                }

                .close::before, .close::after {
                    background-color: var(--color-1-dkr);
                    content: '';
                    border-radius: 1rem;
                    display: block;
                    height: 0.3rem;
                    inset: 50% auto auto 50%;
                    position: absolute;
                    transition: rotate 300ms ease;
                    translate: -50% -50%;
                    width: 2rem;
                }

                .close::before {
                    rotate: -45deg;
                }

                .close::after {
                    rotate: 45deg;
                }

                .close:hover,
                .close:focus {
                    background-color: var(--color-2);
                }

                .close:hover::before{
                    rotate: 0deg;
                }

                .close:hover::after {
                    rotate: 0deg;
                }

                .dot { 
                    aspect-ratio: 1;
                    border-radius: 2rem;
                    display: block;
                    width: 1.6rem;
                }

                .main {
                    flex: 1 0 50vh;
                    margin-bottom: 1rem;
                    overflow: auto;
                    padding-inline: var(--gap-sm);
                    padding-top: var(--gap-sm);
                }

                .menu-full-menu-container {
                    margin-bottom: var(--gap-sm);
                }

                .menu-item {
                    display: block;
                }

                .nav-link,
                .minor-item a {
                    color: var(--content);
                    font-weight: 700;
                    text-decoration: none;
                }
                
                .nav-link {
                    align-items: center;
                    border-radius: 1rem;
                    display: flex;
                    font-size: var(--font-size);
                    padding-block: 0.7em;
                    position: relative;
                }

                .nav-link:hover,
                .nav-link:focus {
                    background-color: var(--color-4);
                    color: var(--color-1-dkr);
                    padding-inline: 1em;
                }

                .current-menu-item .nav-link::before {
                    aspect-ratio: 1;
                    background-color: var(--color-4);
                    border-radius: 1rem;
                    content: '';
                    display: block;
                    left: -1.5em;
                    position: absolute;
                    width: 1rem;
                }

                .minor-item a  {
                    background-color: var(--color-1-dk);
                    display: block;
                    font-size: var(--txt-sm);
                    padding: 1em 1em;
                    text-align: center;
                }

                .minor-item a:hover,
                .minor-item a:focus {
                    background-color: var(--background);
                    border-radius: 0.5rem;
                }

                .footer {
                    margin-top: auto;
                }

                .footer ul {
                    display: flex;
                    gap: 1rem;
                    padding-bottom: 1rem;
                    padding-inline: 1rem;
                }

                .footer li {
                    flex: 1 1 1rem;
                }
            </style>

            <dialog class="menu" data-menu>
                <header class="header" part="h4">
                    <i class="dot" part="bg-color-2"></i>
                    <i class="dot" part="bg-color-3"></i>
                    <i class="dot" part="bg-color-4"></i>

                    <button class="close" part="focus" data-close title="Close Menu"></button>
                </header>
                
                <section class="main">
                    ${this.content()}
                </section>

                <footer class="footer">
                    <ul>
                        <li class="minor-item">
                            <a part="focus" href="/careers">Careers</a>
                        </li>

                        <li class="minor-item">
                            <a part="focus" href="/privacy-policy/">Privacy</a>
                        </li>

                        <li class="minor-item">
                            <a part="focus" href="/media">Media</a>
                        </li>
                    </ul>
                </footer>
            </dialog>
        `
    }
}

customElements.define('zero-menu', ZeroMenu)